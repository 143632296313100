import axios from "axios";
import {EndPoints} from "../config/EndPoints";

export const getAllTemplates = () => {
    return new Promise((resolve, reject) => {
        axios.get(EndPoints.getAllTemplates)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
};

export const getTemplateById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(EndPoints.getTemplate + id)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
};

export const updateTemplate = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(EndPoints.updateTemplate + id)
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
};

export const deleteTemplateById = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(EndPoints.deleteTemplate + id)
            .then(() => resolve())
            .catch(err => reject(err))
    })
}
