import {useState, useEffect} from "react";
import SizeModal from "./SizeModal";
import Button from "../common/UI/Button";
import livingRoomImage from "../../assets/images/living-room-img.png";
import {getAllTemplates, deleteTemplateById, getTemplateById} from "../../utils/templateCRUD";
import {setCanvasLoader, setEditID, setTemplates} from "../../store/editor/editorSlice";
import {useDispatch, useSelector} from "react-redux";
import initCanvas from "../../utils/initCanvas";
import {removeAllCanvasObjects} from "../../utils/helper";
import { fabric } from "fabric";
import axios from "axios";
import {EndPoints} from "../../config/EndPoints";

export default function Templates(){
    const dispatch = useDispatch();
    const { canvas, templates, editID } = useSelector(state => state.editor);
    const [loadingDeleteBtn, setLoadingDeleteBtn] = useState(false);
    const [showSizeModal, setShowSizeModal] = useState(false);
    const [roomTypes, setRoomTypes] = useState([]);


    const loadTemplate = (data, canvas) => {
        const { objects } = data.template;
        fabric.util.enlivenObjects(objects, (objs) => {
            canvas.add(...objs);
            canvas.renderAll();
            dispatch(setCanvasLoader(false));
        });
    }
    const getTemplateData = async () => {
        const data = await getAllTemplates();
        dispatch(setTemplates(data));
    };

    const getRoomTypes = async () => {
        axios.get(EndPoints.getAllFilters)
            .then(res => {
                setRoomTypes(res.data.filters.room_type);
            })
            .catch(err => {
                console.log(err)
            })
    };

    const deleteTemplate = async (id) => {
        setLoadingDeleteBtn(true);
        await deleteTemplateById(id);
        if(id === editID){
            removeAllCanvasObjects(canvas);
            dispatch(setEditID(null));
        }
        setLoadingDeleteBtn(false);
        getTemplateData();
    }

    const editTemplate = async (id) => {
        dispatch(setEditID(id));
        dispatch(setCanvasLoader(true));
        const data = templates.find(e => +e.mood_Template_ID === +id);
        const { mood_board_canvas } = data.template;
        if(canvas) {
            const { width, height } = mood_board_canvas;
            removeAllCanvasObjects(canvas);
            canvas.setWidth(width);
            canvas.setHeight(height);
            loadTemplate(data, canvas);
        } else {
            const initializedCanvas = await initCanvas("editor-canvas", dispatch, mood_board_canvas);
            loadTemplate(data, initializedCanvas);
        }
    }

    const getTemplateName = (id) => {
        return roomTypes.find(e => +e.id === +id)?.name
    }

    useEffect(() => {
        getTemplateData();
        getRoomTypes();
    }, [])

    return (
        <>
            <div className="px-2">
                <SizeModal
                    show={showSizeModal}
                    onClose={() => setShowSizeModal(false)}
                    heading="Select Size and Room Type"
                    roomTypes={roomTypes}
                />
                <div className="panel-title">
                    Choose Mood Boards
                </div>
                <button
                    className="action-btn w-100"
                    type="button"
                    onClick={() => setShowSizeModal(true)}
                >
                    Create New Mood Board
                </button>
                <div className="mt-2 py-2">
                    {templates.map(({mood_Template_ID: id, room_type_id: roomID}) => {
                        return (
                            <div className="flex justify-center items-center flex-col border p-2">
                                <div className="relative template-grid w-full">
                                    <img src={livingRoomImage} height="150px" />
                                    <div className="absolute top-0 template-grid-hover w-full h-full flex justify-center items-center gap-3">
                                        <Button
                                            className="edit-btn"
                                            onClick={() => editTemplate(id)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            className="delete-btn"
                                            size="small"
                                            loading={loadingDeleteBtn}
                                            onClick={() => deleteTemplate(id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    {getTemplateName(roomID)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
