import ButtonLoader from "./ButtonLoader";

export default function Button({loading= false, size, onClick, className, loadingText, children}){
    return (
        <button onClick={onClick} className={className}>
            {loading ? (
                <ButtonLoader
                    size={size}
                    text={loadingText}
                />
            ) : children}
        </button>
    )
}
