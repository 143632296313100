import { setActiveObject, setCanvas } from "../store/editor/editorSlice";
import {fabric} from "fabric";

const selectionCreated = (e, dispatch) => {
    let canvasObject = e.selected[0];
    dispatch(setActiveObject(canvasObject));
};

const selectionUpdated = (e, dispatch) => {
    let canvasObject = e.selected[0];
    dispatch(setActiveObject(canvasObject));
};

const selectionCleared = (e, dispatch) => {
    dispatch(setActiveObject(null));
};

const addedObject = (e) => {
    e.target.setControlsVisibility({
        bl: true,
        br: true,
        mb: true,
        ml: true,
        mr: true,
        mt: true,
        tl: true,
        tr: true,
        mtr: false,
    });
};

const initEvents = (canvas, dispatch) => {
    canvas.on({
        // 'object:modified': modifiedObject,
        "selection:created": (e) => selectionCreated(e, dispatch),
        "selection:updated": (e) => selectionUpdated(e, dispatch),
        "selection:cleared": (e) => selectionCleared(e, dispatch),
        'object:added': addedObject,
        // 'object:removed': objectRemoved,
        // 'object:moving': objectMoved,
        // 'object:scaling': objectScaled,
        // 'object:rotating': objectRotated,
        // 'drop': objectDropped,
        // 'mouse:move': mouseMove,
        // 'mouse:down': mouseDown,
        // 'mouse:up': mouseUp,
        // 'after:render':createBoundingRect,
        // 'text:changed': adjustTextLine
    });
};

const initCanvas = (id, dispatch, size) => {
    const {width, height} = size;
    return new Promise((resolve, reject) => {
        let tempCanvas = new fabric.Canvas(id, {
            targetFindTolerance: 10,
            selection: true,
            preserveObjectStacking: true,
            height,
            width,
            backgroundColor: "white",
        });

        initEvents(tempCanvas, dispatch);
        console.log('tempCanvas', tempCanvas);
        tempCanvas.renderAll();
        window.canvas = tempCanvas;
        dispatch(setCanvas(tempCanvas));
        resolve(tempCanvas);
    })
};


export default initCanvas;
