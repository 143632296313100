import {useRef, useState} from "react";
import {useSelector} from "react-redux";
import { fabric } from "fabric";
import {unGroupObj} from "../utils/helper";

export default function ImagesPanel(){
    const inputRef = useRef();
    const { canvas, activeObject } = useSelector(state => state.editor);
    const [uploadError, setUploadError] = useState("");

    const allowedTypes = [
        'image/png',
        'image/svg+xml',
        // 'application/pdf',
        // 'application/postscript',
        // 'application/illustrator',
        // 'application/postscript'
    ];

    const uploadFile = () => {
        setUploadError("");
        if(!activeObject){
            setUploadError("* Please select a placeholder first")
            return;
        }
        inputRef.current.click();
    };

    // const testIndividualFileType = (file) => {
    //     // Check if the file type is in the list of allowed types
    //     if (allowedTypes.indexOf(file.type) === -1) {
    //         return false;
    //     }
    //     // File type is allowed, return true
    //     return true;
    // };
    //
    // const testFileTypes = (files) => {
    //     let isAllowed = false;
    //     files.forEach((file) => {
    //         isAllowed = testIndividualFileType(file);
    //     });
    //
    //     return isAllowed;
    // };

    const getScaleAndPosition = (img, activeObj) => {
        const placeHolderStroke = 0;
        const { width, height, scaleX, scaleY, left, top } = activeObj;
        const placeHolderWidth = width * scaleX;
        const placeHolderHeight = height * scaleY;
        const {width: imgWidth, height: imgHeight} = img;
        const scaleToWidth = (placeHolderWidth - placeHolderStroke * 2)/imgWidth;
        const scaleToHeight = (placeHolderHeight - placeHolderStroke * 2)/imgHeight;
        let scale = scaleToWidth*imgHeight <= (placeHolderHeight - placeHolderStroke * 2) ? scaleToWidth : scaleToHeight;
        return {
            scale,
            left: left + placeHolderStroke,
            top: top + placeHolderStroke
        };
    };

    const uploadFileToCanvas = (e, storedID) => {
        const placeHolder = storedID ? canvas.getObjects().find(e => e.id === storedID) : activeObject;
        const reader = new FileReader();

        reader.onload = function (event) {
            const imgObj = new Image();
            imgObj.src = event.target.result;
            placeHolder.set({
                image: event.target.result
            })
            imgObj.onload = function () {
                const image = new fabric.Image(imgObj);
                const { scale, left, top } = getScaleAndPosition(image, placeHolder);
                image.scale(scale);
                image.set({
                    placeholderID: placeHolder.id,
                    left,
                    top,
                });
                canvas.add(image);
                let sel = new fabric.ActiveSelection([placeHolder, image], {
                    canvas,
                });
                sel.toGroup();
                canvas.discardActiveObject();
                canvas.renderAll();
            };
        };

        reader.readAsDataURL(e.target.files[0]);
    }
    const newInputHandler = (e) => {
        if(activeObject.type === 'group'){
            const storedID = activeObject._objects[0].id;
            unGroupObj(canvas, {});
            canvas.remove(canvas.getObjects().find(e => e.placeholderID === storedID));
            canvas.requestRenderAll();
            uploadFileToCanvas(e, storedID);

        } else {
            uploadFileToCanvas(e);
        }
    };

    return (
        <>
            <div className="px-2">
                <div className="panel-title">
                    Select a Placeholder and Upload Image
                </div>
                <button
                    className="action-btn w-100"
                    type="button"
                    onClick={uploadFile}
                >
                    Upload your image
                </button>
                {uploadError !== "" && <p style={{color: 'red', fontSize: '14px', fontWeight: '600', margin: '5px 0'}}>{uploadError}</p>}
                <input
                    ref={inputRef}
                    onChange={newInputHandler}
                    onClick={(e) => (e.target.value = null)}
                    // multiple="multiple"
                    className="hidden"
                    type="file"
                    name="file"
                    accept={allowedTypes.join(', ')}
                />
            </div>
        </>
    )
}
