export const optionsToAdd = ['custom'];

export const unGroupObj = (canvas, options) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject.type === 'group') {
        const items = activeObject._objects;
        activeObject._restoreObjectsState();
        canvas.remove(activeObject);
        for (let i = 0; i < items.length; i++) {
            canvas.add(items[i]);
        }

        canvas.renderAll();
    }

    canvas.discardActiveObject();

    activeObject.set({ ...options });

    // activeObject.setControlsVisibility({
    //     ml: false,
    //     mr: false,
    //     mt: false,
    //     mb: false,
    // });

    canvas.requestRenderAll();
};

export const removeAllCanvasObjects = (canvas) => {
    canvas.getObjects().forEach( obj => {
        canvas.remove(obj);
    });
    canvas.renderAll();
}
