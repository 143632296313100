import rectangleImg from "../assets/icons/rectangle.png";
import { fabric } from "fabric";
import {useSelector} from "react-redux";
const uuid = require('uuid');

export default function ShapesPanel() {
    const { canvas } = useSelector(state => state.editor);
    const addRect = () => {
        if(!canvas) return;
        let rect = new fabric.Rect({
            id: uuid.v4(),
            left: canvas.width/4,
            top: canvas.height/4,
            fill: '#beeedc',
            width: 200,
            height: 200,
            // stroke: 1,
            // cornerStrokeColor: '#71CFBC',
            custom: {
                category: ""
            },
            image: null
        });

        canvas.add(rect);
        canvas.renderAll();
    }
    return (
        <>
            <div className="px-2">
                <div className="panel-title">
                    Add Shapes
                </div>
                <div className="shapes-panel-grid grid grid-cols-3 gap-2">
                    <div className="hover-shapes-grid cursor-pointer" onClick={addRect}>
                        <img src={rectangleImg} width="100px" />
                    </div>
                </div>
            </div>
        </>
    )
}
