import React from "react";
import ShapesPanel from "../ShapesPanel";
import ImagesPanel from "../ImagesPanel";
import Templates from "../templates/Templates";

export default function LeftPanel ({ tool  }) {
    return (
        <>
            <div className="left-panel-wrapper relative py-4 px-2">
                {/*<h4 style={{textTransform: 'capitalize', marginBottom: '15px'}}>{`${tool} panel`}</h4>*/}
                {tool === 'shapes' && <ShapesPanel />}
                {tool === 'images' && <ImagesPanel />}
                {tool === 'moodBoards' && <Templates />}
            </div>
        </>

    )
}
