import EditBar from "./EditBar";
import PageLoader from "../common/UI/PageLoader";
import {useSelector} from "react-redux";

export default function Manager(){
    const { canvasLoader } = useSelector(state => state.editor);
    return (
        <>
            <div className="manager-wrapper grow relative" id="canvas-wrapper">
                {canvasLoader && <PageLoader />}
                <EditBar />
                <div className="canvas-container flex justify-center py-10">
                    <canvas  id="editor-canvas" />
                </div>
            </div>
        </>
    )
}
