export function ShapesIcon ({fill}) {
    return <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill={fill} d="M8.135 12.736l-.764 1.058A6.297 6.297 0 1 1 13.281 5h-1.138a5.286 5.286 0 1 0-4.644 7.8 5.201 5.201 0 0 0 .636-.064zM11 6v6.923l1 1.385V7h10v10h-8.056l.723 1H23V6zm6.044 17H1.956L9.5 12.554zM9.5 14.262L3.911 22H15.09z"/>
        <path fill="none" d="M0 0h24v24H0z"/>
    </svg>

}
export const ImagePanelIcon = ({fill})=><svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_845_29)">
        <path d="M3.61276 18.0155C3.53786 18.0155 3.46319 17.9869 3.40604 17.9298C3.29174 17.8155 3.29174 17.6304 3.40604 17.5161L10.3079 10.6142C10.4222 10.5002 10.6073 10.5002 10.7214 10.6142L12.9713 12.8642L18.3796 7.45585C18.4939 7.34154 18.679 7.34154 18.7933 7.45585L22.8292 11.4918C22.9435 11.6061 22.9435 11.7912 22.8292 11.9052C22.7149 12.0195 22.5299 12.0195 22.4155 11.9052L18.5863 8.076L13.1782 13.4845C13.0639 13.5986 12.8789 13.5986 12.7646 13.4845L10.5146 11.2346L3.81948 17.9298C3.76232 17.9869 3.68743 18.0155 3.61276 18.0155Z" fill={fill}/>
        <path d="M5.19208 10.5282C4.09558 10.5282 3.20349 9.63634 3.20349 8.5396C3.20349 7.44287 4.09558 6.55078 5.19208 6.55078C6.28859 6.55078 7.18068 7.44287 7.18068 8.5396C7.18068 9.63634 6.28859 10.5282 5.19208 10.5282ZM5.19208 7.13567C4.41799 7.13567 3.78838 7.76528 3.78838 8.5396C3.78838 9.31393 4.41799 9.9433 5.19208 9.9433C5.96618 9.9433 6.59578 9.31393 6.59578 8.5396C6.59578 7.76528 5.96618 7.13567 5.19208 7.13567Z" fill={fill}/>
        <path d="M23.1486 19.1851H1.62392C1.20472 19.1851 0.863647 18.844 0.863647 18.4246V4.62085C0.863647 4.20166 1.20472 3.86035 1.62392 3.86035H23.1486C23.5678 3.86035 23.9091 4.20142 23.9091 4.62085V18.4246C23.9091 18.844 23.568 19.1851 23.1486 19.1851ZM1.62392 4.44525C1.52713 4.44525 1.44854 4.52406 1.44854 4.62085V18.4246C1.44854 18.5212 1.52713 18.6002 1.62392 18.6002H23.1486C23.2454 18.6002 23.3242 18.5212 23.3242 18.4246V4.62085C23.3242 4.52406 23.2454 4.44525 23.1486 4.44525H1.62392Z" fill={fill}/>
    </g>
    <defs>
        <clipPath id="clip0_845_29">
            <rect width="23.0455" height="23.0455" fill="white" transform="translate(0.863647)"/>
        </clipPath>
    </defs>
</svg>
