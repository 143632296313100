import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    canvas: null,
    activeObject: null,
    editID: null,
    templates: [],
    canvasLoader: false,
    roomType: {}
};

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        initCanvas: function (state, action) {
            // TODO: initialize canvas here
        },
        setCanvas: function (state, action) {
            state.canvas = action.payload;
        },
        setActiveObject: function (state, action) {
            state.activeObject = action.payload;
        },
        setEditID: function (state, action) {
            state.editID = action.payload;
        },
        setTemplates: function (state, action) {
            state.templates = action.payload;
        },
        setCanvasLoader: function (state, action) {
            state.canvasLoader = action.payload;
        },
        setRoomType: function (state, action) {
            state.roomType = action.payload;
        }
    }
});

export const { setCanvas, setActiveObject, setEditID, setTemplates, setCanvasLoader, setRoomType } = editorSlice.actions;
