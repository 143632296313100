import Header from "../main/Header";
import LeftPanel from "../main/LeftPanel";
import Manager from "../main/Manager";
import Sidebar from "../main/Sidebar";
import {useState} from "react";

export default function Layout(){
    const [selectedTool, setSelectedTool] = useState('moodBoards');
    return (
        <>
            <Header />
            <div className="flex">
                <Sidebar
                    changeTool={(tool)=>setSelectedTool(tool)}
                    selectedTool={selectedTool}
                />
                <LeftPanel
                    tool={selectedTool}
                />
                <Manager />
            </div>
        </>
    )
}
