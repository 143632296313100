import Modal from "../common/UI/Modal";
import {useState} from "react";
import {useDispatch} from "react-redux";
import initCanvas from "../../utils/initCanvas";
import {setEditID, setRoomType} from "../../store/editor/editorSlice";
import DropDown from "../common/UI/DropDown";

export default function SizeModal({show, onClose, heading, roomTypes}){
    const options = roomTypes.map(r => ({label: r.name, value: r.id}));
    const dispatch = useDispatch();
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);
    const [selectedRoomType, setSelectedRoomType] = useState(options[0]?.value);
    const [errorMsg, setErrorMsg] = useState(null);

    const createMoodBoard = () => {
        setErrorMsg(null);
        if(!width || !height || width < 1 || height < 1){
            setErrorMsg("Please enter valid width and height");
            return;
        }
        if(!selectedRoomType){
            setErrorMsg("Please select room type");
            return;
        };
        dispatch(setEditID(null));
        initCanvas("editor-canvas", dispatch, {width, height})
        onClose();
    };

    return (
        <Modal
            show={show}
            onClose={onClose}
            onSubmit={createMoodBoard}
            heading={heading}
            divider={false}
            actionTitle="Create"
        >
            <form className="rounded px-8 pt-2 pb-2 w-full">
                <label className="block text-black text-sm font-bold mb-1">
                    Size:
                </label>
                <div className="flex gap-2 items-center">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        type="number"
                        placeholder="Width"
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                    />
                    x
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                        type="number"
                        placeholder="Height"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                    />
                    (in pixels)
                </div>
                <div className="flex gap-2 items-center mt-2">
                    <label className="text-gray-700 text-sm mb-2">
                        Room Type:
                    </label>
                    <DropDown
                        options={options}
                        selected={selectedRoomType}
                        setSelected={(value) => {
                            setSelectedRoomType(value);
                            dispatch(setRoomType(options.find(e => e.value === value)))
                        }}
                    />
                </div>
                {errorMsg && <div style={{color: 'red'}}>
                    *{errorMsg}
                </div>}
            </form>
        </Modal>
    )
}
