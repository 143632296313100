import React  from "react";
import Button from "./Button";

const Modal = ({show, onClose, heading, onSubmit, divider, actionTitle, children}) => {
    return (
        <>
            {show ? (
                <>
                    <div className="moodboard-custom-modal flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-1/4 my-6 mx-auto max-w-3xl">
                            <div className="border-outline shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className={`flex items-start justify-between p-5 ${divider && "border-b border-solid border-gray-300"} rounded-t`}>
                                    <h3 className="text-3xl font=semibold">{heading}</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={onClose}
                                    >
                                        <span className="text-black opacity-7 h-6 w-6 text-xl flex justify-center items-center py-0 rounded-full">
                                            x
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    {children}
                                </div>
                                <div className={`flex items-center justify-end p-6 ${divider && "border-t border-solid border-blueGray-200"} rounded-b`}>
                                    <Button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        onClick={onClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className="modal-primary-btn text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        onClick={onSubmit}
                                    >
                                        {actionTitle}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Modal;
