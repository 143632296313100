import React from "react";
import { ShapesIcon, ImagePanelIcon } from "../../assets/icons";

export default function Sidebar ({ selectedTool, changeTool }) {

    const sideBarItems = [
        {
            key: "moodBoards",
            title: "Mood Boards",
        },
        {
            key: "shapes",
            title: "Shapes",
        },
        {
            key: "images",
            title: "Images",
        },
    ];

    const Icons = ({type}) => {
        const fill = selectedTool === type ? '#71CFBC' : 'black';
        return (
            <>
                {type === "moodBoards" && <ShapesIcon fill={fill} />}
                {type === "shapes" && <ShapesIcon fill={fill} />}
                {type === "images" && <ImagePanelIcon fill={fill} />}
            </>
        )
    }
    const iconStyle = (tool) => {
       let obj = {
           color: `${selectedTool === tool ? '#71CFBC' : 'black'}`,
           fontWeight: `${selectedTool === tool ? '700' : '400'}`,
           borderBottom: `1px solid ${selectedTool === tool ? '#71CFBC' : '#EDEDED'}`
       };
       return obj;
    }
    return (
        <>
            <div style={{minWidth: '135px'}} className="sidebar-wrapper px-4 py-1 relative flex-col gap-px justify-center items-center text-center">
                {sideBarItems.map(({ key, title }) => {
                    return <div
                        onClick={()=>changeTool(key)}
                        className={`py-2 cursor-pointer flex flex-col items-center gap-y-2`}
                        style={iconStyle(key)}
                    >
                        <span>{title}</span>
                        <Icons type={key} />
                    </div>
                })}
            </div>
        </>

    )
}
