import decoristLogo from "../../assets/images/decorist-logo.png";

export default function Header () {
    return (
        <>
            <nav className="header-wrapper flex py-4 px-8">
                <div className="grow" style={{height: '50px', width: '200px', overflow: 'hidden'}}>
                    <img src={decoristLogo} width="200px" style={{transform: "translateY(-72px)"}}/>
                </div>
                {/*<div className="flex space-x-6 px-4">*/}
                {/*    <div className="white-btn">Admin</div>*/}
                {/*</div>*/}
            </nav>
        </>

    )
}
