import './styles/index.css';
import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="custom-mood-board-admin">
     <Layout />
    </div>
  );
}

export default App;
