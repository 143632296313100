import AppConfig from "./AppConfig";

const ApiPrefix = AppConfig.BASE_URL + "/api/v1/";

export const EndPoints = {
    getAllTemplates: ApiPrefix + "templates/get",
    getTemplate: ApiPrefix + "templates/get?temp_id=",
    addTemplate: ApiPrefix + "templates/add",
    updateTemplate: ApiPrefix + "templates/edit?temp_id=",
    deleteTemplate: ApiPrefix + "templates/delete?temp_id=",
    getAllFilters: ApiPrefix + "moodboard_filter/get",
};
