import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import axios from "axios";
import {EndPoints} from "../../config/EndPoints";
import {getAllTemplates} from "../../utils/templateCRUD";
import {setTemplates} from "../../store/editor/editorSlice";
import Button from "../common/UI/Button";
import DropDown from "../common/UI/DropDown";
import {optionsToAdd} from "../../utils/helper";

export default function EditBar () {
    const dispatch = useDispatch();
    const { canvas, activeObject, editID, roomType } = useSelector(state => state.editor);
    const [category, setCategory] = useState(activeObject?.custom?.category);
    const [position, setPosition] = useState(activeObject?.custom?.position || 'left');
    const [saveLoading, setSaveLoading] = useState(false);

    const positionOptions = [
        {label: 'left', value: 'left'},
        {label: 'right', value: 'right'},
        {label: 'front', value: 'front'},
        {label: 'back', value: 'back'}
    ];

    const bringForward = () => {
        canvas.bringForward(activeObject);
    }
    const sendBackwards = () => {
        canvas.sendBackwards(activeObject);
    }

    const addNewTemplate = (data) => {
        axios.post(EndPoints.addTemplate, data)
            .then(res => {
                setSaveLoading(false);
                getAllTemplates()
                    .then(response => {
                        dispatch(setTemplates(response));
                    })
                    .catch(error => console.log(error))
            })
            .catch(err => console.log(err))
    }

    const updateTemplate = (data) => {
        axios.put(EndPoints.updateTemplate + editID, data)
            .then(res => {
                setSaveLoading(false);
                getAllTemplates()
                    .then(response => {
                        dispatch(setTemplates(response));
                    })
                    .catch(error => console.log(error))
            })
            .catch(err =>{
                console.log(err)
                setSaveLoading(false);
            })
    }
    const saveChanges = () => {
        setSaveLoading(true);
        let objects = canvas.getObjects();
        let moodBoardItems = [];
        objects.forEach(e => {
            const { left, top, _objects, custom } = e;
            const _custom = custom || _objects[0].custom;
            let obj = {
                left,
                top,
                width: e.getScaledWidth(),
                height: e.getScaledHeight(),
                category: _custom.category,
                position: _custom.position,
                // image: _objects[0].image
            };
            moodBoardItems.push(obj);
        })
        let data = {
            template: {
                mood_board_canvas: {
                    width: canvas.width,
                    height: canvas.height
                },
                items: moodBoardItems,
                objects: objects.map(e => e.toJSON(optionsToAdd))
            },
            room_type_id: roomType.value,
        }
        console.log('DATA', data);
        if(editID) {
            updateTemplate(data);
        } else addNewTemplate(data);
    }

    useEffect(() => {
        if(activeObject?.type === 'group'){
            setCategory(activeObject?._objects[0]?.custom?.category);
            setPosition(activeObject?._objects[0]?.custom?.position);
        } else {
            setCategory(activeObject?.custom?.category);
            setPosition(activeObject?.custom?.position);
        }
    }, [activeObject])

    useEffect(() => {
        if(activeObject?.type === 'group'){
            activeObject._objects[0].set({
                custom: {
                    category,
                    position
                }
            })
        } else {
            activeObject?.set({
                custom: {
                    category,
                    position
                }
            });
        }
    }, [category, position])
    return (
        <>
            <div className="flex p-4 gap-2" style={{background: 'white', minHeight: '71px'}}>
                {activeObject &&  <div className="flex gap-1 items-center">
                    <label className="block text-gray-700 text-sm mb-2" htmlFor="category">
                        Category:
                    </label>
                    <input type="text" id="category"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           value={category}
                           onChange={(e) => {
                               setCategory(e.target.value);
                           }}
                           placeholder="Category" />
                </div>}
                {activeObject && <div className="flex gap-1 items-center">
                    <label className="block text-gray-700 text-sm mb-2" htmlFor="category">
                        Position:
                    </label>
                    <DropDown
                        options={positionOptions}
                        selected={position}
                        setSelected={setPosition}
                    />
                </div>}
                {activeObject && <div className="flex justify-center items-center action-btn text-sm" onClick={bringForward}>Bring Forward</div>}
                {activeObject && <div className="flex justify-center items-center action-btn text-sm" onClick={sendBackwards}>Send Backward</div>}
                <div className="grow"></div>
                <div className="flex space-x-6 px-4">
                    <Button
                        className="white-btn text-sm"
                        size="small"
                        loading={saveLoading}
                        loadingText="Saving..."
                        onClick={saveChanges}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </>

    )
}
